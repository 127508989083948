import { render, staticRenderFns } from "./LogCard.vue?vue&type=template&id=c13de9e2&scoped=true&lang=pug&"
import script from "./LogCard.vue?vue&type=script&lang=ts&"
export * from "./LogCard.vue?vue&type=script&lang=ts&"
import style0 from "./LogCard.vue?vue&type=style&index=0&id=c13de9e2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c13de9e2",
  null
  
)

export default component.exports