








































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Interfaces
import { Log } from '@store/users/interfaces'

@Component({
  name: 'LogCard',
})

export default class LogCardComponent extends Vue {
  @Prop({default: () => null}) private log!: Log
}


